import React, { useContext } from 'react';
import { Row, Col, Space } from 'antd';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { CheckoutContext } from '../Contexts/CheckoutContext';
import FreightCard from '../Components/FreightCard';
import ItemsCard from '../Components/ItemsCard';
import TotalCard from '../Components/TotalCard';
import FormTitle from '../Components/FormTitle';
import DiscountCard from '../Components/DiscountCard';

const Cart = () => {
  const { form } = useContext(CheckoutContext);
  const items = form.getFieldValue('items');

  return (
    <Row style={{ width: '100%' }} gutter={16}>
      <Col span={24}>
        <FormTitle title={'PRODUTOS'} icon={<ShoppingCartOutlined />} />
      </Col>
      <Col span={24}>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          {items.map((item, index) => (
            <ItemsCard key={`${index}-${item.sky}`} item={item} />
          ))}
          <FreightCard />
          <DiscountCard />
          <TotalCard />
        </Space>
      </Col>
    </Row>
  );
};

export default Cart;
