export function removeSpecialChars(str) {
  return str.replace(/[^\w\s]/gi, '');
}

export function getPaymentMethodsHumanRead(condition) {
  switch (condition) {
    case 'MP':
      return 'Mercado Pago'
    case 'CARD':
      return 'Cartão'
    case 'PIX':
      return 'PIX'
    default:
      return condition
  }
}
