import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { CheckoutContext } from '../Contexts/CheckoutContext';

const FreightCard = () => {
  const { form } = useContext(CheckoutContext);
  const shippingAmount = form.getFieldValue('shippingAmount');
  const shippingAddress = form.getFieldValue('shippingAddress');
  const { city, state } = shippingAddress;

  if (city && state) {
    return (
      <Container gutter={8}>
        <Col>
          <b>
            FRETE para {city} / {state}:
          </b>
        </Col>
        <Col>
          <b>R$ {parseFloat(+shippingAmount).toFixed(2)}</b>
        </Col>
      </Container>
    );
  }
  return <></>;
};

export default FreightCard;

const Container = styled(Row)`
  border: 0.1rem solid #c9c9c9;
  padding: 1rem 0.75rem;
  text-align: right;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  > &:nth-child(1) {
    text-align: left;
  }
`;
