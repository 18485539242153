import React, { useContext } from 'react';
import { Form, Row, Col } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import { StyledInput as Input, StyledInputMasked as MaskedInput, StyledSelect as Select } from '../Components/Input.styled';
import { CheckoutContext } from '../Contexts/CheckoutContext';
import { brazilianStates } from '../Constants';
import FloatLabel from '../Components/FloatLabel';
import FormTitle from '../Components/FormTitle';

const ShippingAddress = () => {
  const { readOnly } = useContext(CheckoutContext);

  return (
    <Row style={{ width: '100%' }} gutter={16}>
      <Col span={24}>
        <FormTitle title={'ENDEREÇO DE ENTREGA'} icon={<HomeOutlined />} />
      </Col>
      <Col xs={24} md={8}>
        <FloatLabel label="CEP">
          <Form.Item name={['shippingAddress', 'zipcode']}>
            <MaskedInput disabled={readOnly} format="#####-###" mask="_" />
          </Form.Item>
        </FloatLabel>
      </Col>

      <Col span={16} />

      <Col xs={24} md={10}>
        <FloatLabel label="Endereço">
          <Form.Item name={['shippingAddress', 'address']}>
            <Input disabled={readOnly} />
          </Form.Item>
        </FloatLabel>
      </Col>

      <Col xs={24} md={6}>
        <FloatLabel label="Número">
          <Form.Item name={['shippingAddress', 'number']}>
            <Input disabled={readOnly} />
          </Form.Item>
        </FloatLabel>
      </Col>

      <Col xs={24} md={8}>
        <FloatLabel label="Complemento">
          <Form.Item name={['shippingAddress', 'complement']}>
            <Input disabled={readOnly} />
          </Form.Item>
        </FloatLabel>
      </Col>

      <Col xs={24} md={8}>
        <FloatLabel label="Bairro">
          <Form.Item name={['shippingAddress', 'neighborhood']}>
            <Input disabled={readOnly} />
          </Form.Item>
        </FloatLabel>
      </Col>

      <Col xs={24} md={8}>
        <FloatLabel label="Cidade">
          <Form.Item name={['shippingAddress', 'city']}>
            <Input disabled={readOnly} />
          </Form.Item>
        </FloatLabel>
      </Col>

      <Col xs={24} md={8}>
        <FloatLabel label="Estado">
          <Form.Item name={['shippingAddress', 'state']}>
            <Select disabled={readOnly}>
              {brazilianStates.map((x) => (
                <Select.Option key={x.value} value={x.value}>
                  {x.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </FloatLabel>
      </Col>
    </Row>
  );
};
export default ShippingAddress;
