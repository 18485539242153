import React, { useContext } from 'react';
import { Form, Row, Col } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { CheckoutContext } from '../Contexts/CheckoutContext';
import { StyledInput as Input, StyledInputMasked as MaskedInput, StyledSelect as Select } from '../Components/Input.styled';
import FloatLabel from '../Components/FloatLabel';
import FormTitle from '../Components/FormTitle';

const Person = () => {
  const { form, readOnly } = useContext(CheckoutContext);
  const documentType = form.getFieldValue('documentType');

  return (
    <Row style={{ width: '100%' }} gutter={16}>
      <Col span={24}>
        <FormTitle title={'DETALHES DO FATURAMENTO'} icon={<UserOutlined />} />
      </Col>
      <Col xs={24} md={6}>
        <FloatLabel label="Data do pedido">
          <Form.Item name="createdAt">
            <Input disabled={readOnly} />
          </Form.Item>
        </FloatLabel>
      </Col>
      <Col xs={24} md={6}>
        <FloatLabel label="Tipo de Pessoa">
          <Form.Item name="documentType">
            <Select disabled={readOnly}>
              <Select.Option value={'CPF'}>Física</Select.Option>
              <Select.Option value={'CNPJ'}>Jurídica</Select.Option>
            </Select>
          </Form.Item>
        </FloatLabel>
      </Col>

      <Col xs={24} md={12}>
        <FloatLabel label={documentType === 'CPF' ? 'Nome' : 'Razão Social'}>
          <Form.Item name={['customer', 'name']}>
            <Input disabled={readOnly} />
          </Form.Item>
        </FloatLabel>
      </Col>
      <Col xs={24} md={12}>
        <FloatLabel label="E-mail">
          <Form.Item name={['customer', 'email']}>
            <Input disabled={readOnly} />
          </Form.Item>
        </FloatLabel>
      </Col>
      <Col xs={24} md={6}>
        <FloatLabel label={documentType}>
          <Form.Item name={['customer', 'document']}>
            {documentType === 'CPF' ? (
              <MaskedInput disabled={readOnly} format="###.###.###-##" mask="_" />
            ) : (
              <MaskedInput disabled={readOnly} format="##.###.###/####-##" mask="_" />
            )}
          </Form.Item>
        </FloatLabel>
      </Col>
      <Col xs={24} md={6}>
        <FloatLabel label="Celular">
          <Form.Item name="phone">
            <MaskedInput disabled={readOnly} format="(##) ####-#####" mask="_" />
          </Form.Item>
        </FloatLabel>
      </Col>
    </Row>
  );
};
export default Person;
