import React from 'react';
import { Space, Switch, Typography, Button as Btn } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

const FormTitle = (props) => {
  const { title, icon, handleAction, status, button, disabled = false } = props;

  return (
    <Container>
      <Space size="small">
        {icon ? icon : null} <TitleText>{title}</TitleText>
      </Space>
      {!button && handleAction ? <Switch disabled={disabled} checked={status} onChange={handleAction} /> : null}
      {button && handleAction ? (
        <Button disabled={disabled} onClick={handleAction}>
          {button}
        </Button>
      ) : null}
    </Container>
  );
};

export default FormTitle;

const Container = styled.div`
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  border-bottom: 0.1rem solid #c9c9c9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 1rem;
`;

const Button = styled(Btn)`
  font-weight: 600;
  font-size: 0.6rem;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const TitleText = styled(Text)``;
