import React, { useContext } from 'react';
import { Row } from 'antd';
import styled from 'styled-components';
import { CheckoutContext } from '../Contexts/CheckoutContext';

const TotalCard = () => {
  const { form } = useContext(CheckoutContext);
  const total = form.getFieldValue('amountTotal');
  const voucher = form.getFieldValue('voucher');

  const amountTotal = (total ?? 0) - (voucher ?? 0);

  return (
    <Container gutter={8}>
      <b>TOTAL: R$ {parseFloat(+amountTotal).toFixed(2)}</b>
    </Container>
  );
};

export default TotalCard;

const Container = styled(Row)`
  background: #e6e6e6;
  padding: 1rem 0.75rem;
  justify-content: flex-end;
  letter-spacing: 2px;
  font-size: 1.5rem;
  border-radius: 0.25rem;
`;
