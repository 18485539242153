import React, { useContext } from 'react';
import { Row, Col, Space } from 'antd';
import Finish from '../Components/Finish';
import { CheckoutContext } from '../Contexts/CheckoutContext';

const SubmitFooter = () => {
  const { submiting, paymentCCSuccess } = useContext(CheckoutContext);

  return (
    <Row style={{ width: '100%' }} gutter={16}>
      <Col span={24}>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Col>
            Os seus dados pessoais serão utilizados para processar a sua compra, apoiar a sua experiência em todo este site e para outros fins descritos na nossa política de
            privacidade.
          </Col>

          {!paymentCCSuccess && <Finish loading={submiting} />}
        </Space>
      </Col>
    </Row>
  );
};

export default SubmitFooter;
