import React from 'react';
import { Result, Button } from 'antd';

export default function Page404() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Desculpe, a página que você procura não existe!"
      extra={
        <Button type="primary">
          <a href="https://clubedapicanhatrend.com.br">VOLTAR AO SITE</a>
        </Button>
      }
    />
  );
}
