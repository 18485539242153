import React from "react";
import { Row } from "antd";
import styled from "styled-components";

const PaymentCard = (props) => {
  const { children } = props;

  return <Container gutter={8}>{children}</Container>;
};

export default PaymentCard;

const Container = styled(Row)`
  border: 0.1rem solid #c9c9c9;
  padding: 1rem 0.75rem;
  border-radius: 0.25rem;
  label {
    left: 1rem;
  }
`;
