import React from 'react';
import { Image, Row, Col, Typography } from 'antd';
import styled from 'styled-components';
import TrendLogo from '../Assets/TrendLogo.png';

const HeaderInformation = () => (
  <Container>
    <Image src={TrendLogo} height={120} width={100} preview={false} style={logo} />
    <TrendInfo>
      <Col span={24}>
        <TrendText> Clube da Picanha Trend</TrendText>
      </Col>
      <Col>
        <TrendText>CNPJ 23.577.462/0002-52</TrendText>
      </Col>
    </TrendInfo>
  </Container>
);

export default HeaderInformation;

const logo = {
  maxWidth: '195px',
};

const Container = styled.div`
  max-width: 1248px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 0;
  margin: -30px auto 0;
`;

const TrendInfo = styled(Row)`
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 50px;
`;

const { Text } = Typography;
const TrendText = styled(Text)`
  color: #ffffff;
  font-size: 0.8rem;
`;
