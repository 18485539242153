import styled from "styled-components";
import { Select, Input } from "antd";
import NumberFormat from "react-number-format";

export const StyledInput = styled(Input)`
  border-radius: 4px;
  height: 3rem;
  padding: 2rem 0.5rem 1rem;
  background-color: transparent !important;
`;

export const StyledInputMasked = styled(NumberFormat).attrs(() => ({
  className: "ant-input",
}))`
  border-radius: 4px;
  height: 3rem;
  padding: 2rem 0.5rem 1rem;
  background-color: transparent !important;
  background-position: 98% 50%;
  background-repeat: no-repeat;
  background-color: #fff;
`;

export const StyledSelect = styled(Select)`
  height: 3rem;
  background-color: transparent !important;
  .ant-select-selector {
    padding: 1rem 0.5rem 0.3rem !important;
    background-color: transparent !important;
    border-radius: 4px !important;
    height: 3rem !important;
  }
`;
