import React, { useContext } from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import { CheckoutContext } from '../Contexts/CheckoutContext';

const DiscountCard = () => {
  const { form } = useContext(CheckoutContext);
  const discount = form.getFieldValue('discount');
  const coupon = form.getFieldValue('coupon');

  if (discount) {
    return (
      <Container gutter={8}>
        <Col>
          <b>
            Desconto:
          </b>
        </Col>
        <Col>
          <b>R$ {parseFloat(+discount).toFixed(2)}</b>
        </Col>
        {coupon && coupon?.name && coupon?.disclaimer && (
          <Col>
            <b>Cupom: {coupon?.name}</b> ({coupon?.disclaimer})
          </Col>
        )}
      </Container>
    );
  }
  return <></>;
};

export default DiscountCard;

const Container = styled(Row)`
  border: 0.1rem solid #c9c9c9;
  padding: 1rem 0.75rem;
  text-align: left;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  > &:nth-child(2) {
    text-align: right;
  }
`;
