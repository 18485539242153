import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

const ItemsCard = ({ item }) => {
  const { quantityBox, name, boxType, priceBox } = item;

  return (
    <Container gutter={8}>
      <Col span={20}>{name}</Col>
      <Col span={4}>
        <b>x {quantityBox}</b>
      </Col>
      <Col span={24}>
        <b>R$ {parseFloat(+priceBox * +quantityBox).toFixed(2)}</b> (R$ {parseFloat(+priceBox).toFixed(2)} / {boxType})
      </Col>
    </Container>
  );
};

export default ItemsCard;

const Container = styled(Row)`
  border: 0.1rem solid #c9c9c9;
  padding: 1rem 0.75rem;
  text-align: right;
  border-radius: 0.25rem;
  .ant-col:nth-child(1) {
    text-align: left;
  }
`;
