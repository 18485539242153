import React, { useContext } from 'react';
import { PageHeader, Form, Row, Col, Space, Spin, Result } from 'antd';
import { CheckoutContext } from './Contexts/CheckoutContext';
import topBG from './Assets/topo.png';
import footerBG from './Assets/MenuBG.jpg';
import HeaderInformation from './Components/HeaderInformation';
import FooterInformation from './Components/FooterInformation';
import PersonView from './Views/Person';
import BillingAddressView from './Views/BillingAddress';
import ShippingAddressView from './Views/ShippingAddress';
import CartView from './Views/Cart';
import PaymentView from './Views/Payment';
import SubmitFooterView from './Views/SubmitFooter';
import { initialValues } from './Constants';

const App = () => {
  const { loading, form, showConfirm, paymentCCSuccess, showMPSuccess, showMPFailure, showMpPending, mpLink, pixQrCode, showLinkError } = useContext(CheckoutContext);
  const clearScreen = paymentCCSuccess || showMPSuccess || showMPFailure || showMpPending || showLinkError;

  return (
    <>
      <PageHeader
        onBack={() => null}
        style={{
          backgroundImage: `url(${topBG})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          height: 180,
          position: 'relative',
          marginBottom: 80,
        }}
      >
        <HeaderInformation />
      </PageHeader>

      {showLinkError && (
        <Row justify="center" style={{ width: '100%' }} gutter={16}>
          <Col span={24}>
            <Result status="error" title="Atenção" subTitle={`Algo errado ocorreu ao abrir o pagamento do seu pedido. Verifique o link acessado.`} />
          </Col>
        </Row>
      )}

      {showMPSuccess && (
        <Row justify="center" style={{ width: '100%' }} gutter={16}>
          <Col span={24}>
            <Result status="success" title="Pagamento realizado!" subTitle={`O seu pagamento foi realizado com sucesso.`} />
          </Col>
        </Row>
      )}

      {showMPFailure && (
        <Row justify="center" style={{ width: '100%' }} gutter={16}>
          <Col span={24}>
            <Result
              status="error"
              title="Erro ao realizar recebimento"
              subTitle={`O seu pagamento não foi processado com sucesso. Você pode tentar novamente através do link que recebeu no fechamento do seu pedido.`}
            />
          </Col>
        </Row>
      )}

      {showMpPending && (
        <Row justify="center" style={{ width: '100%' }} gutter={16}>
          <Col span={24}>
            <Result status="success" title="Solicitação processada!" subTitle={`O seu pagamento encontra-se em processamento. Em breve você receberá uma atualização de status.`} />
          </Col>
        </Row>
      )}
      {paymentCCSuccess && (
        <Row justify="center" style={{ width: '100%' }} gutter={16}>
          <Col span={24}>
            <Result status="success" title="Solicitação processada!" subTitle={`O seu pagamento encontra-se em processamento. Em breve você receberá uma atualização de status.`} />
          </Col>
        </Row>
      )}
      <Spin spinning={loading}>
        {!clearScreen && (
          <Form
            style={{
              padding: '16px 16px 96px',
              maxWidth: 1280,
              margin: 'auto',
            }}
            form={form}
            initialValues={initialValues}
            onFinish={showConfirm}
            autoComplete="off"
          >
            <Row gutter={16}>
              <Col xs={24} md={14}>
                <PersonView />
                <BillingAddressView />
                <ShippingAddressView />
              </Col>

              <Col xs={24} md={10}>
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                  <CartView />
                  <PaymentView />
                  {!mpLink.url && !pixQrCode.qr_code && <SubmitFooterView />}
                </Space>
              </Col>
            </Row>
          </Form>
        )}
      </Spin>

      <PageHeader
        onBack={() => null}
        style={{
          backgroundImage: `url(${footerBG})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          height: 300,
          position: 'relative',
          padding: 0,
        }}
      >
        <FooterInformation />
      </PageHeader>
    </>
  );
};

export default App;
