import React, { useContext } from 'react';
import { message, Form, Row, Col, Space, Button, Tabs, Divider, Modal } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CreditCardOutlined, CopyOutlined } from '@ant-design/icons';
import { StyledInput as Input, StyledInputMasked as MaskedInput, StyledSelect as Select } from '../Components/Input.styled';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { CheckoutContext } from '../Contexts/CheckoutContext';
import FloatLabel from '../Components/FloatLabel';
import FormTitle from '../Components/FormTitle';
import PaymentCard from '../Components/PaymentCard';
import PixLogo from '../Assets/pix_logo.png';
import PixIcon from '../Assets/ic_pix.png';
import MPIcon from '../Assets/ic_mp.png';
import MPLogo from '../Assets/mp_logo.png';
import CCIcon from '../Assets/ic_cc.png';
import { getPaymentMethodsHumanRead } from '../Helpers';

const { error } = Modal;

const { TabPane } = Tabs;

const Payment = () => {
  const { paymentMethod, setPaymentMethod, form, installmentsData, setInstallmentsData, pixQrCode, mpLink } = useContext(CheckoutContext);
  const { issuer, payer_costs = [], payment_method_id } = installmentsData;
  const { secure_thumbnail = '' } = issuer;
  const total = form.getFieldValue('amountTotal');
  const voucher = form.getFieldValue('voucher');

  const amountTotal = (total ?? 0) - (voucher ?? 0);
  const coupon = form.getFieldValue('coupon');

  const mpPublicKey = form.getFieldValue(['branch', 'mpPublicKey']);

  const changePaymentType = (t) => {
    switch (t) {
      case '2':
        setPaymentMethod('pix');
        break;
      case '3':
        setPaymentMethod('mercado_pago');
        break;
      default:
        setPaymentMethod('credit_card');
    }
    form.resetFields(['cardData']);
  };

  const getInstallments = async () => {
    try {
      if (!form.getFieldValue('cardData')) return;
      const cardNumber = form.getFieldValue('cardData').cardNumber ? form.getFieldValue('cardData').cardNumber.replace(/ /gi, '') : '';
      if (cardNumber.length < 16 || !amountTotal) return;

      const mp = new window.MercadoPago(mpPublicKey, { locale: 'pt-BR' });

      const [data] = await mp.getInstallments({ amount: `${amountTotal}`, bin: cardNumber.substr(0, 6), locale: 'pt-BR', processingMode: 'aggregator' });
      setInstallmentsData(data);
    } catch (err) {
      console.log(err);
      setInstallmentsData({ payer_costs: [], issuer: { secure_thumbnail: '' } });
      error({
        okText: 'OK',
        title: 'Erro nos dados do cartão',
        icon: <ExclamationCircleOutlined />,
        content: <p style={{ marginTop: 20 }}>{'Não foi possível reconhecer a numeração do seu cartão. Confira e redigite os números novamente.'}</p>,
        onOk() {},
      });
    }
  };

  return (
    <Row style={{ width: '100%' }} gutter={16}>
      <Col span={24}>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Row style={{ width: '100%' }} gutter={16}>
            <Col span={24}>
              <FormTitle title={'FORMA DE PAGAMENTO'} icon={<CreditCardOutlined />} />
            </Col>
            {!!coupon?.paymentMethods && (
              <Col span={24}>
                <Col>
                  <span style={{ color: 'red' }}>
                    Atenção: O cupom utilizado em sua compra permite a(s) seguinte(s) forma(s) de pagamento:{' '}
                    {coupon?.paymentMethods.map((x, index) => {
                      return `${index > 0 ? ', ' : ''}${getPaymentMethodsHumanRead(x)}`;
                    })}
                  </span>
                </Col>
              </Col>
            )}
          </Row>
          <div className="card-container">
            <Tabs defaultActiveKey="1" type="card" onChange={changePaymentType}>
              {(!coupon?.paymentMethods || coupon?.paymentMethods?.indexOf('CARD') > -1) && (
                <TabPane
                  tab={
                    <span>
                      <img style={{ width: '30px' }} alt="MP" src={CCIcon} />
                      &nbsp;Cartão de crédito
                    </span>
                  }
                  key="1"
                  disabled={mpLink.link || pixQrCode.qr_code}
                >
                  {paymentMethod === 'credit_card' && (
                    <PaymentCard>
                      <>
                        <Row style={{ width: '100%' }} gutter={16}>
                          <Col span={24}>
                            <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                              <Col>Atenção: Não utilize o recurso autocompletar do seu navegador. Digite manualmente os dados do seu cartão.</Col>
                            </Space>
                          </Col>
                        </Row>
                        <Divider />

                        <Col xs={24} md={12}>
                          <FloatLabel label="Número do cartão">
                            <Form.Item
                              name={['cardData', 'cardNumber']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Número do cartão obrigatório',
                                },
                              ]}
                            >
                              <MaskedInput
                                style={secure_thumbnail ? { backgroundImage: `url(${secure_thumbnail})` } : {}}
                                onBlur={getInstallments}
                                autoFocus
                                format={payment_method_id === 'amex' ? '#### ###### #####' : '#### #### #### ####'}
                                mask="_"
                              />
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                        <Col xs={24} md={12}>
                          <FloatLabel label="Nome no cartão">
                            <Form.Item
                              name={['cardData', 'cardholderName']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Nome no cartão obrigatório',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                        <Col xs={24} md={12}>
                          <FloatLabel label="Validade (MM/AAAA)">
                            <Form.Item
                              name={['cardData', 'cardExpiration']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Validade cartão obrigatório',
                                },
                                {
                                  pattern: /[\d]{2}\/[\d]{4}/,
                                  message: 'Formato de validade inválido',
                                },
                              ]}
                            >
                              <MaskedInput format="##/####" mask="_" />
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                        <Col xs={24} md={12}>
                          <FloatLabel label="Código de Segurança">
                            <Form.Item
                              name={['cardData', 'securityCode']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Código de Segurança obrigatório',
                                },
                              ]}
                            >
                              <MaskedInput format={payment_method_id === 'amex' ? '####' : '###'} mask="_" />
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                        <Col xs={24}>
                          <FloatLabel label="CPF do Portador">
                            <Form.Item name={['cardData', 'identificationNumber']} rules={[{ required: true, message: 'CPF obrigatório' }]}>
                              <MaskedInput format="###.###.###-##" mask="_" />
                            </Form.Item>
                          </FloatLabel>
                        </Col>

                        <Col xs={24}>
                          <FloatLabel label="Parcelar em">
                            <Form.Item
                              name={['cardData', 'installments']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Parcelamento obrigatório',
                                },
                              ]}
                            >
                              <Select disabled={payer_costs.length === 0} defaultActiveFirstOption>
                                {payer_costs.map((payerCost) => (
                                  <Select.Option key={payerCost.installments} value={payerCost.installments}>
                                    {payerCost.recommended_message} {payerCost.installment_rate > 0 ? ' - Com Juros' : ' - Sem Juros'}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </FloatLabel>
                        </Col>
                      </>
                    </PaymentCard>
                  )}
                </TabPane>
              )}
              {(!coupon?.paymentMethods || coupon?.paymentMethods?.indexOf('PIX') > -1) && (
                <TabPane
                  tab={
                    <span>
                      <img style={{ width: '30px' }} alt="MP" src={PixIcon} />
                      &nbsp;PIX
                    </span>
                  }
                  key="2"
                  disabled={mpLink.link || pixQrCode.qr_code}
                >
                  <PaymentCard>
                    <>
                      <Col xs={24}>
                        {pixQrCode.qr_code ? (
                          <Row justify="center">
                            <Col span={24} className="text-center">
                              <h3>Utilize o QR Code abaixo para efetuar o pagamento</h3>
                            </Col>
                            <Col span={24} className="text-center">
                              <img style={{ width: '150px', height: '150px' }} alt="pix" src={`data:image/png;base64,${pixQrCode.qr_code_base64}`} />
                            </Col>
                            <Col span={24} className="text-center">
                              <CopyToClipboard text={pixQrCode.qr_code} onCopy={() => message.success('Código copiado para área de transferência!')}>
                                <Button style={{ marginBottom: 10, marginTop: 10 }} type="primary" icon={<CopyOutlined />}>
                                  Copiar código
                                </Button>
                              </CopyToClipboard>
                            </Col>
                          </Row>
                        ) : (
                          <Col xs={24}>
                            <strong>Pagamento via PIX</strong> <br />
                            Clique em finalizar compra e siga as instruções.
                            <br />
                            <br />
                            <img style={{ height: '100px' }} alt="pix" src={PixLogo} />
                          </Col>
                        )}
                      </Col>
                    </>
                  </PaymentCard>
                </TabPane>
              )}
              {(!coupon?.paymentMethods || coupon?.paymentMethods?.indexOf('MP') > -1) && (
                <TabPane
                  tab={
                    <span>
                      <img style={{ width: '30px' }} alt="MP" src={MPIcon} />
                      &nbsp;Mercado Pago
                    </span>
                  }
                  key="3"
                  disabled={mpLink.link || pixQrCode.qr_code}
                >
                  <PaymentCard>
                    <>
                      <Col xs={24}>
                        {mpLink.url ? (
                          <Row justify="center">
                            <Col span={24} className="text-center">
                              <h3>Acesse o link abaixo para continuar seu pagamento</h3>
                            </Col>
                            <Col span={24} className="text-center">
                              <a href={mpLink.url}>{mpLink.url}</a>
                            </Col>
                            <CopyToClipboard text={mpLink.url} onCopy={() => message.success('Link copiado para área de transferência!')}>
                              <Button style={{ marginBottom: 10, marginTop: 10 }} type="primary" icon={<CopyOutlined />}>
                                Copiar link
                              </Button>
                            </CopyToClipboard>
                          </Row>
                        ) : (
                          <Col xs={24}>
                            <strong>Pagamento via Mercado Pago</strong> <br />
                            Clique em finalizar compra e siga as instruções.
                            <br />
                            <br />
                            <img style={{ height: '100px' }} alt="Pagar com Mercado Pago" src={MPLogo} />
                          </Col>
                        )}
                      </Col>
                    </>
                  </PaymentCard>
                </TabPane>
              )}
            </Tabs>
          </div>
        </Space>
      </Col>
    </Row>
  );
};
export default Payment;
