import React from 'react';
import styled from 'styled-components';
import { Image, Row, Col, Typography } from 'antd';
import Pix from '../Assets/pix_logo.png';
import MP from '../Assets/mp_logo.png';

import AlphaSSL from '../Assets/alphassl-logo.png';
export const APP_VERSION = process.env.REACT_APP_VERSION;

export const FOOTER_TEXT = `Copyright Trend © ${new Date().getFullYear()} (v${APP_VERSION})`;

const FooterInformation = () => (
  <Container>
    <Row>
      <Col xs={24} xl={8} style={{ textAlign: 'center', marginTop: '10px' }}>
        <Image src={Pix} width={130} height={40} preview={false} />
      </Col>
      <Col xs={24} xl={8} style={{ textAlign: 'center', marginTop: '10px' }}>
        <Image src={MP} width={130} preview={false} />
      </Col>
      <Col xs={24} xl={8} style={{ textAlign: 'center', marginTop: '10px' }}>
        <Image src={AlphaSSL} width={232} height={66} preview={false} />
      </Col>
    </Row>
    <Row>
      <Col md={24} style={{ textAlign: 'right', marginTop: '10px' }}>
        <TrendText>{FOOTER_TEXT}</TrendText>
      </Col>
    </Row>
  </Container>
);

export default FooterInformation;

const Container = styled.div`
  padding: 60px 0;
  margin: auto;
  height: 100%;
`;

const { Text } = Typography;

const TrendText = styled(Text)`
  margin-top: 50px;
  color: #ffffff;
  font-size: 0.8rem;
  margin-right: 30px;
`;
