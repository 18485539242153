import React, { useCallback, useState, createContext, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import axios from 'axios';
import { Form, Modal } from 'antd';
import ReactPixel from 'react-facebook-pixel';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { removeSpecialChars } from '../Helpers';

const { confirm, error } = Modal;

export const CheckoutContext = createContext({});

export function CheckoutProvider({ children }) {
  const { id, uuid, ecommerceId, ecommerceKey } = useParams();
  const [installmentsData, setInstallmentsData] = useState({ payer_costs: [], issuer: { secure_thumbnail: '' } });
  const [loading, setLoading] = useState(true);
  const [submiting, setSubmiting] = useState(false);
  const [form] = Form.useForm();
  const [readOnly, setReadOnly] = useState(true);
  const [showLinkError, setShowLinkError] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('credit_card');
  const [pixQrCode, setPixQrCode] = useState({ qr_code: null, qr_code_base64: null });
  const [mpLink, setMPLink] = useState({ link: null });
  const location = useLocation();
  const showMPSuccess = location.pathname.toLocaleLowerCase().indexOf('wallet-mp/success') > -1;
  const showMPFailure = location.pathname.toLocaleLowerCase().indexOf('wallet-mp/failure') > -1;
  const showMpPending = location.pathname.toLocaleLowerCase().indexOf('wallet-mp/pending') > -1;

  const total = form.getFieldValue('amountTotal');
  const voucher = form.getFieldValue('voucher');

  const mpPublicKey = form.getFieldValue(['branch', 'mpPublicKey']);

  const amountTotal = (total ?? 0) - (voucher ?? 0);

  useEffect(() => {
    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: +process.env.REACT_APP_FACEBOOK_PIXEL_DEBUG > 0, // enable logs
    };
    ReactPixel.init(process.env.REACT_APP_FACEBOOK_PIXEL_ID, undefined, options);
  }, []);

  const sendPixelEvent = useCallback((eventName, payload = {}) => {
    try {
      ReactPixel.track(eventName, payload);
    } catch (error) {
      console.log('sendPixelEvent > error', error);
    }
  }, []);

  const [paymentCCSuccess, setPaymentCCSuccess] = useState(false);

  const sendGAConversionEvent = () => {
    try {
      const transaction_id = form.getFieldValue('uuid');
      window.gtag('event', 'conversion', {
        send_to: 'AW-811186202/qOXoCIO2xr0CEJrw5oID',
        value: parseFloat(+amountTotal).toFixed(2),
        currency: 'BRL',
        transaction_id,
      });
    } catch (err) {
      console.log(`sendGAConversionEvent ~ err`, err);
    }
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(`${process.env.REACT_APP_TREND_API}/orders-public/${id}/${uuid}`);
        const { customer } = data;
        form.setFieldsValue({
          ...data,
          phone: customer.wa_id,
          createdAt: format(parseISO(data.createdAt), 'dd/MM/yyyy HH:mm'),
          documentType: customer.document.length > 11 ? 'CNPJ' : 'CPF',
        });
        setLoading(false);
        setReadOnly(true);
      } catch (err) {
        error({
          okText: 'OK',
          title: 'Atenção',
          icon: <ExclamationCircleOutlined />,
          content: <p style={{ marginTop: 20 }}>{err.response?.data?.message || 'Não foi possível obter os dados do pedido no momento.'}</p>,
          onOk() {},
        });
        setLoading(false);
        setShowLinkError(true);
      }
    };

    if (!!id && !!uuid) {
      getData();
    }
  }, [id, uuid, ecommerceId, ecommerceKey, form]);

  function showConfirm(data) {
    var msg = '';
    var method_name = '';

    if (paymentMethod === 'pix') {
      msg = 'Solicitar QR CODE';
      method_name = 'PIX';
    }
    if (paymentMethod === 'credit_card') {
      msg = 'Pagar';
      method_name = 'Cartão de crédito';
    }
    if (paymentMethod === 'mercado_pago') {
      msg = 'Gerar link';
      method_name = 'Mercado Pago';
    }

    confirm({
      okText: msg,
      cancelText: 'Cancelar',
      title: 'Tem certeza?',
      icon: <ExclamationCircleOutlined />,
      content: <p style={{ marginTop: 20 }}>{`O método de pagamento escolhido foi ${method_name}.`}</p>,
      onOk() {
        if (paymentMethod === 'pix') {
          return processPixPayment();
        } else if (paymentMethod === 'mercado_pago') {
          return processMPPayment();
        } else {
          return processCreditCardPayment(data.cardData);
        }
      },
    });
  }

  const processPixPayment = async () => {
    try {
      setSubmiting(true);
      const orderUuid = form.getFieldValue('uuid');
      const orderId = form.getFieldValue('_id');
      const { data } = await axios.post(`${process.env.REACT_APP_TREND_API}/payment/pix/${orderId}/${orderUuid}`);
      setPixQrCode(data);
      sendGAConversionEvent();
      sendPixelEvent('Purchase', {
        value: amountTotal,
        currency: 'BRL',
      });
    } catch (err) {
      error({
        okText: 'OK',
        title: 'Atenção',
        icon: <ExclamationCircleOutlined />,
        content: <p style={{ marginTop: 20 }}>{err.response?.data?.message || 'Não foi possível obter os dados da transação. Tente novamente mais tarde.'}</p>,
        onOk() {},
      });
    }
    setSubmiting(false);
  };

  const processMPPayment = async () => {
    try {
      setSubmiting(true);
      const orderUuid = form.getFieldValue('uuid');
      const orderId = form.getFieldValue('_id');
      const { data } = await axios.post(`${process.env.REACT_APP_TREND_API}/payment/mp/${orderId}/${orderUuid}`);
      setMPLink(data);
      sendGAConversionEvent();
      sendPixelEvent('Purchase', {
        value: amountTotal,
        currency: 'BRL',
      });
    } catch (err) {
      error({
        okText: 'OK',
        title: 'Atenção',
        icon: <ExclamationCircleOutlined />,
        content: <p style={{ marginTop: 20 }}>{err.response?.data?.message || 'Não foi possível obter os dados da transação. Tente novamente mais tarde.'}</p>,
        onOk() {},
      });
    }
    setSubmiting(false);
  };

  const processCreditCardPayment = async (cardData) => {
    let tokenData;

    const { cardNumber, cardholderName, cardExpiration, securityCode, identificationNumber, installments = 1 } = cardData;
    const orderUuid = form.getFieldValue('uuid');
    const orderId = form.getFieldValue('_id');
    try {
      setSubmiting(true);

      const mp = new window.MercadoPago(mpPublicKey, { locale: 'pt-BR' });

      const cardExpirationMonth = cardExpiration.split('/')[0];
      const cardExpirationYear = cardExpiration.split('/')[1];
      const params = {
        cardNumber: cardNumber.replace(/ /gi, ''),
        cardholderName,
        cardExpirationMonth,
        cardExpirationYear,
        securityCode,
        identificationType: 'CPF',
        identificationNumber: removeSpecialChars(identificationNumber),
      };
      tokenData = await mp.createCardToken(params);

      if (!tokenData.luhn_validation) {
        setSubmiting(false);

        error({
          okText: 'OK',
          title: 'Atenção',
          icon: <ExclamationCircleOutlined />,
          content: <p style={{ marginTop: 20 }}>Não foi possível validar o seu cartão, verifique os dados digitados e tente novamente.</p>,
          onOk() {},
        });

        return;
      }

      const LOG = {
        service: 'MERCADO_PAGO',
        url: `/card_tokens?public_key=${mpPublicKey}&locale=pt-BR&js_version=2.0.0`,
        order: orderId,
        request: {
          card_number: cardNumber?.replace(/\D+/g, '').replace(/(\d{6})\d+(\d{4})/g, '$1XXXXXX$2'),
          cardholder: {
            name: cardholderName,
            identification: {
              type: 'CPF',
              number: identificationNumber?.replace(/\D+/g, ''),
            },
          },
          expiration_month: cardExpirationMonth,
          expiration_year: cardExpirationYear,
        },
        response: tokenData,
        responseStatusCode: Number('000'),
      };

      try {
        await axios.post(`${process.env.REACT_APP_TREND_API}/logs`, LOG);
      } catch {
        //
      }

      sendGAConversionEvent();
    } catch (err) {
      console.log(err);
      error({
        okText: 'OK',
        title: 'Atenção',
        icon: <ExclamationCircleOutlined />,
        content: <p style={{ marginTop: 20 }}>{err.response?.data?.message || 'Não foi possível validar o seu cartão de crédito no momento.'}</p>,
        onOk() {},
      });
      setSubmiting(false);
      return;
    }
    try {
      axios
        .post(`${process.env.REACT_APP_TREND_API}/payment/card/${orderId}/${orderUuid}`, { token: tokenData.id, installments })
        .then((response) => {
          setSubmiting(false);

          const { data } = response;
          if (data.status && data.status !== 'rejected') {
            setPaymentCCSuccess(true);
            sendPixelEvent('Purchase', {
              value: amountTotal,
              currency: 'BRL',
            });
          } else {
            error({
              okText: 'OK',
              title: 'Pagamento recusado',
              icon: <ExclamationCircleOutlined />,
              content: <p style={{ marginTop: 20 }}>{'Não foi possível aprovar seu pagamento, verifique os dados do seu cartão ou utilize outro meio de pagamento.'}</p>,
              onOk() {},
            });
          }
        })
        .catch((err) => {
          error({
            okText: 'OK',
            title: 'Atenção',
            icon: <ExclamationCircleOutlined />,
            content: <p style={{ marginTop: 20 }}>{err.response?.data?.message || 'Não foi possível obter os dados da transação. Tente novamente mais tarde.'}</p>,
            onOk() {},
          });
          setSubmiting(false);
        });
    } catch (err) {
      error({
        okText: 'OK',
        title: 'Atenção',
        icon: <ExclamationCircleOutlined />,
        content: <p style={{ marginTop: 20 }}>{err.response?.data?.message || 'Não foi possível processar o pagamento no momento. Tente novamente mais tarde.'}</p>,
        onOk() {},
      });
      setSubmiting(false);
    }
  };

  return (
    <CheckoutContext.Provider
      value={{
        loading,
        form,
        readOnly,
        showLinkError,
        paymentMethod,
        setPaymentMethod,
        submiting,
        showConfirm,
        installmentsData,
        setInstallmentsData,
        pixQrCode,
        mpLink,
        paymentCCSuccess,
        showMPSuccess,
        showMPFailure,
        showMpPending,
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
}
