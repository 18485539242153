import React from 'react';
import 'antd/dist/antd.css';
import './index.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactDOM from 'react-dom';
import App from './App';
import { CheckoutProvider } from './Contexts/CheckoutContext';
import Page404 from './Views/404';

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/checkout/:id/:uuid">
        <CheckoutProvider>
          <App />
        </CheckoutProvider>
      </Route>
      <Route path="/checkout-ecommerce/:ecommerceId/:ecommerceKey">
        <CheckoutProvider>
          <App />
        </CheckoutProvider>
      </Route>
      <Route path="/wallet-mp/success/:id/:uuid">
        <CheckoutProvider>
          <App />
        </CheckoutProvider>
      </Route>
      <Route path="/wallet-mp/pending/:id/:uuid">
        <CheckoutProvider>
          <App />
        </CheckoutProvider>
      </Route>
      <Route path="/wallet-mp/failure/:id/:uuid">
        <CheckoutProvider>
          <App />
        </CheckoutProvider>
      </Route>
      <Route path="*">
        <Page404 />
      </Route>
    </Switch>
  </Router>,
  document.getElementById('root')
);
