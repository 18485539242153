import React from "react";
import styled from "styled-components";

const FloatLabel = (props) => {
  const { children, label } = props;
  // const [focus, setFocus] = useState(false);
  // const float =
  //   disabled || focus || value?.length > 0;
  const float = true
  return (
    // <div onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
    <div>
      {children}
      <Label float={float}>{label}</Label>
    </div>
  );
};

export default FloatLabel;

const Label = styled.label(({ float }) => ({
  position: "absolute",
  top: float ? "4px" : "15px",
  left: "1rem",
  display: "flex",
  fontSize: "1rem",
  fontWeight: "normal",
  transition: "0.2s ease all",
  zIndex: float ? "0" : "-1",
}));
