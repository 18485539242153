import React from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

const Finish = (props) => (
  <FinishButton {...props} htmlType="submit">
    FINALIZAR COMPRA
  </FinishButton>
);

export default Finish;

const FinishButton = styled(Button)`
  height: 5rem;
  width: 100%;
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 1.25rem;
  background: #a61307;
  &:hover {
    color: #ffffff;
    background: #85160d;
  }
`;
